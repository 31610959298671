import React, { useState, useEffect } from "react"
import classNames from "classnames"
import FormInput from "../FormInput"
import FormSelect from "../FormSelect"

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faHome, faBuilding } from "@fortawesome/free-solid-svg-icons"
import address from "../../../assets/locs.json"
import AddressTypeChoices from "./AddressTypeChoices.js"

const INPUT_STREET_PLACEHOLDER = "Unit/House No., Building, Street"
const SELECT_PROVINCE_PLACEHOLDER = "Select province"
const SELECT_CITY_PLACEHOLDER = "Select city"
const SELECT_BARANGAY_PLACEHOLDER = "Select barangay"

const Address = ({
  isRequired,
  fieldNames = {},
  values = {},
  isNationwide,
  helper,
  setFieldValue,
  hasNoStreetAddress,
  withAddressType,
  withBarangay,
  className,
}) => {
  const { addressType, streetAddress, province, city, barangay } = fieldNames
  const [listOfProvince, setProvinces] = useState([])
  const [listOfCity, setListOfCity] = useState([])
  const [listOfBarangays, setListOfBarangays] = useState([])

  useEffect(() => {
    initializeProvinces()
    const { Cities } = address.filter(loc => loc.Province === "Metro Manila")[0]
    setListOfCity(sortCities(Cities))
    //eslint-disable-next-line
  }, [])

  const initializeProvinces = () => {
    let options = address.map(remapProvinces)
    const metroManila = options.filter(({ value }) => value === "Metro Manila")
    if (isNationwide) setProvinces(options)
    else setProvinces(metroManila)
  }

  const remapProvinces = ({ Province }) => ({
    value: Province,
    label: Province,
  })

  const sortCities = cities => {
    const sortedCities = cities
      .map(({ City, Barangays }) => ({
        value: City,
        label: City,
        barangays: Barangays,
      }))
      .sort((a, b) => {
        return a.value > b.value ? 1 : -1
      })

    return sortedCities
  }

  const handleOnChange = ({ value }, setFieldValue) => {
    const { Cities } = address.filter(loc => loc.Province === value)[0]
    setListOfCity(sortCities(Cities))
    setFieldValue(city || "city", "")
  }

  const handleOnCityChange = ({ value }, setFieldValue) => {
    const selectedCity = listOfCity.find(item => item.value === value)
    setListOfBarangays(
      selectedCity.barangays.map(item => ({
        value: item,
        label: item,
      }))
    )
    setFieldValue(barangay || "barangay", "")
  }

  return (
    <div className={classNames("mb-1", className)}>
      {addressType && withAddressType && (
        <AddressTypeChoices
          addressTypeName={addressType}
          values={values}
          setFieldValue={setFieldValue}
        />
      )}
      {!hasNoStreetAddress && (
        <FormInput
          name={streetAddress || "streetAddress"}
          label="Street Address"
          helper={helper}
          placeholder={INPUT_STREET_PLACEHOLDER}
          isRequired={isRequired}
          hideOptional={!isRequired}
          maxLength={100}
        />
      )}
      <FormSelect
        name={province || "province"}
        label="Province"
        isRequired={isRequired}
        value={values.province}
        placeholder={SELECT_PROVINCE_PLACEHOLDER}
        options={listOfProvince}
        onChange={handleOnChange}
        hideOptional={!isRequired}
      />
      <FormSelect
        name={city || "city"}
        label="City"
        placeholder={SELECT_CITY_PLACEHOLDER}
        value={values.city}
        isRequired={isRequired}
        options={listOfCity}
        onChange={handleOnCityChange}
        hideOptional={!isRequired}
      />

      {withBarangay && (
        <FormSelect
          name={barangay || "barangay"}
          label="Barangay"
          placeholder={SELECT_BARANGAY_PLACEHOLDER}
          value={values.barangay}
          isRequired={isRequired}
          options={listOfBarangays}
          hideOptional={!isRequired}
          isSelectable
        />
      )}
    </div>
  )
}

export default Address
