// import { formatNumber } from './moneyFormat'

import {
  pxConsentAndAuthorization,
  drConsentAndAuthorization,
} from "../components/forms/enrollment-form/utils/consent-and-authorization-options"

const handleBuildData = input => {
  let output = ""
  input.forEach(data => {
    output += `<li>${data}</li>`
  })
  return output
}

export function patientEnrollmentTemplate(enrollmentDetails) {
  const {
    lastName,
    firstName,
    middleName,
    birthDate,
    birthMonth,
    birthYear,
    mobileNumber,
    alternativeClinicLandlineNumber,
    emailAddress,
    streetAddress,
    addressType,
    latestRxDateMonth,
    latestRxDateDate,
    latestRxDateYear,
    lastCheckupDateMonth,
    lastCheckupDateDate,
    lastCheckupDateYear,
    city,
    province,
    doctor,
    secondaryContact,
    isTransferringPatient,
    experienceAdverseEvents,
    heartCarePatientCode,
    planYourFirstOrder,
  } = enrollmentDetails

  return /*html*/ `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <title>Patient Enrollment Receipt Email</title>
        <style>
          @media only screen and (max-width: 770px) {
            .logo {
              width: 100%;
            }

            table {
            border-collapse: collapse;
            width: 100%;
          }
          }

          @media screen {
            .logo {
              width: 30%;
            }

            .content {
            width: 70%;
            }

            table {
            border-collapse: collapse;
            width: 60%;
            }
          }

          body {
            font-family: Lato, sans-serif;
          }

          strong {
            font-color: #0d6d6e;
          }

          table,
          th,
          td {
            border: none;
          }

          td {
            padding: 10px;
          }

          td:nth-child(1) {  
            width: 20%;
          }

          strong {
            color: #0d6d6e;
          }

          th {
            padding: 10px;
            background-color: #0d6d6e;
            color: white;
          }

          .container {
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            flex-direction: row;
            padding: 25px;
            width: 100%;
            justify-content: center;
          }

          .content {
            width: 80%;
          }

          .is-primary {
            color: #0d6d6e;
          }

          .is-normal {
            color: #000;
          }

          .is-bordered {
            border: 1px solid black;
          }

          .is-right {
            text-align: right;
          }

          .is-center {
            text-align: center;
          }
          
          .no-wrap{
            white-space: nowrap;
          }

        </style>
      </head>

      <body>
        <div class="container">
          <div class="content">
            <img
              src="https://s3-ap-southeast-1.amazonaws.com/data.medgrocer.com/public/logo/mgsolo_teal.png"
              class="logo"
            />
            <p class="subtitle">Hi <b>${firstName} ${lastName}</b>,</p>
            <p>Thank you for choosing to be a part of the Pulse Care Program. This email serves to acknowledge that we have received your enrollment. Please expect an SMS that verifies your eligibility by the next working day. Please note that this email does not confirm your eligibility to the program.</p>
            <p>
            Once enrolled, please report any adverse event through MedGrocer.
            </p>

            <p class="subtitle is-primary"><strong>Get Your Medicine Assistance</strong></p>
            <p>Go to <strong><a href="https://pulsecare.medgrocer.com/">pulsecare.medgrocer.com</a></strong> to start ordering Sacubitril-Valsartan. Remember to upload your prescription to avail of the Medicine Assistance.</p>

            <p class="subtitle is-primary"><strong>Mechanics</strong></p>
            <ol>
              <li>All tablets must be purchased from <strong><a href="https://pulsecare.medgrocer.com/">pulsecare.medgrocer.com</a></strong> to be eligible for the Pulse Care Medicine Assistance.</li>
              <li>Combined quantity of Medicine Assistance and patient purchase should not exceed the quantity indicated in the prescription.</li>
              <li>Quantity of Medicine Assistance should not exceed the quantity ordered.</li>
              <li>Sacubitril-Valsartan is dispensed in blisters of 14 tablets in line with Quality Assurance principles.</li>
            </ol>

            <p class="subtitle is-primary"><strong>Review Your Responses</strong></p>
            <p>Please review your responses and make sure all details are correct. If you would like to make any revisions, please send an email to <strong><a href="https://pulsecare.medgrocer.com/">pulsecare.medgrocer.com</a></strong> within 24 hours.</p>
            
            <br />
            <table class="table">
              <tbody>
                <tr>
                  <td colspan="2"><strong>Personal Details</strong></td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>${lastName}</td>
                </tr>
                <tr>
                  <td>First Name</td>
                  <td>${firstName}</td>
                </tr>
                <tr>
                  <td>Middle Name</td>
                  <td>${middleName}</td>
                </tr>
                <tr>
                  <td>Birth Date</td>
                  <td>${birthMonth} ${birthDate} ${birthYear}</td>
                </tr>
                <tr>
                  <td>Mobile Number</td>
                  <td>${mobileNumber}</td>
                </tr>
                <tr>
                  <td>Alternative Landline</td>
                  <td>${alternativeClinicLandlineNumber || "N/A"}</td>
                </tr>
                <tr>
                  <td>Email Address</td>
                  <td>${emailAddress}</td>
                </tr>
                <tr>
                  <td>You are a patient transferring from the Heart Care Program</td>
                  <td>${isTransferringPatient}</td>
                </tr>
                ${
                  isTransferringPatient === "Yes"
                    ? `<tr>
                  <td>Heart Care Program Serial Code</td>
                  <td>${heartCarePatientCode || "N/A"}</td>
                </tr><tr>
                  <td>Have you reported any adverse events?</td>
                  <td>${experienceAdverseEvents || "N/A"}</td>
                </tr>`
                    : ""
                }

                <tr>
                  <td>You will likely order within:</td>
                  <td>${planYourFirstOrder}</td>
                </tr>

                <tr>
                  <td colspan="2"></td>
                </tr>

                <tr>
                  <td colspan="2"><strong>Address</strong></td>
                </tr>
                <tr>
                  <td>Address Type</td>
                  <td>${addressType}</td>
                </tr>
                <tr>
                  <td>Street Address</td>
                  <td>${streetAddress}</td>
                </tr>
                <tr>
                  <td>Province</td>
                  <td>${province.value}</td>
                </tr>
                <tr>
                  <td>City</td>
                  <td>${city.value}</td>
                </tr>

                <tr>
                  <td colspan="2"></td>
                </tr>

                <tr>
                  <td colspan="2"><strong>Secondary Contact Person</strong></td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>${secondaryContact.name}</td>
                </tr>
                <tr>
                  <td>Mobile Number</td>
                  <td>${secondaryContact.mobileNumber}</td>
                </tr>
                
                <tr>
                  <td colspan="2"></td>
                </tr>

                <tr>
                  <td colspan="2"><strong>Consultation Details</strong></td>
                </tr>
                <tr>
                  <td>Doctor</td>
                  <td>${doctor}</td>
                </tr>
                <tr>
                  <td>Date of Latest Prescription</td>
                  <td>${latestRxDateMonth} ${latestRxDateDate} ${latestRxDateYear}</td>
                </tr>
                <tr>
                  <td>Date of Last Check-up</td>
                  <td>${`${lastCheckupDateMonth || ""} ${lastCheckupDateDate ||
                    ""} ${lastCheckupDateYear || ""}`}</td>
                </tr>
              </tbody>
            </table>

            <br />
            <p class="subtitle is-primary"><strong>Consent and Authorization</strong></p>
            <ul>
                ${handleBuildData(pxConsentAndAuthorization)}
            </ul>

            
          </div>
        </div>
      </body>
    </html>
  `
}

export function doctorEnrollmentTemplate(enrollmentDetails) {
  const {
    lastName,
    firstName,
    middleName,
    mobileNumber,
    emailAddress,
    primaryOffice,
    secondaryOffice,
    PRCLicenseNumber,
    referral,
    clinicLandlineNumber,
    alternativeClinicLandlineNumber,
    flyersReceived,
    planToEnrollPatient,
  } = enrollmentDetails
  return /*html*/ `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <title>Patient Enrollment Receipt Email</title>
        <style>
        @media only screen and (max-width: 770px) {
            .logo {
              width: 100%;
            }

            table {
            border-collapse: collapse;
            width: 100%;
          }
          }

          @media screen {
            .logo {
              width: 30%;
            }

            .content {
            width: 70%;
            }

            table {
            border-collapse: collapse;
            width: 60%;
            }
          }

          body {
            font-family: Lato, sans-serif;
          }

          strong {
            font-color: #0d6d6e;
          }

          table,
          th,
          td {
            border: none;
          }

          td {
            padding: 10px;
          }

          td:nth-child(1) {  
            width: 20%;
          }

          strong {
            color: #0d6d6e;
          }

          th {
            padding: 10px;
            background-color: #0d6d6e;
            color: white;
          }

          .container {
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            flex-direction: row;
            padding: 25px;
            width: 100%;
            justify-content: center;
          }

          .content {
            width: 80%;
          }

          .is-primary {
            color: #0d6d6e;
          }

          .is-bordered {
            border: 1px solid black;
          }

          .is-right {
            text-align: right;
          }

          .is-center {
            text-align: center;
          }
          
          .no-wrap{
            white-space: nowrap;
          }
        </style>
      </head>

      <body>
        <div class="container">
          <div class="content">
            <img
              src="https://s3-ap-southeast-1.amazonaws.com/data.medgrocer.com/public/logo/mgsolo_teal.png"
              class="logo"
            />
            <p class="subtitle">Hi Dr. <b>${firstName} ${lastName}</b>,</p>
            <p>Thank you for choosing to be a part of the Pulse Care Program. This email serves as the official confirmation that your enrollment to the program has been <strong>APPROVED</strong>. We have included in this email the important details for you to get started.</p>
        
            <p class="subtitle is-primary"><strong>Enroll your Patients</strong></p>
           
            <ol>
            <li>You can start instructing your patients to enroll in the program once you receive this email.</li>
            <li>Receive physical copies of the Pulse Care communication materials within three working days for Metro Manila.</li>
            <li>While waiting for the physical copies, you can download the communication materials <a href="https://drive.google.com/file/d/15uCIKuGKbck7uH4vHLHCtCKSZzkVAUXB/view?usp=sharing"><strong>here</strong></a>.</li>
            </ol>


            <p>For any questions about the program, please don't hesitate to contact us at <strong>pulsecare@medgrocer.com</strong>. For questions about Sacubitril-Valsartan and its properties, please contact the Medical Representative assigned to you.</p>
            
            <p class="subtitle is-primary"><strong>Review Your Responses</strong></p>
            <p>Please review your responses and make sure all details are correct. If you would like to make any revisions, please send an email to <strong>pulsecare@medgrocer.com</strong> within 24 hours.</p>
            <br />

            <table class="table">
              <tbody>
                <tr >
                  <td colspan="2"><strong>Personal Details</strong></td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>${lastName}</td>
                </tr>
                <tr>
                  <td>First Name</td>
                  <td>${firstName}</td>
                </tr>
                <tr>
                  <td>Middle Name</td>
                  <td>${middleName}</td>
                </tr>
                <tr>
                  <td>Mobile Number</td>
                  <td>${mobileNumber}</td>
                </tr>
                <tr>
                  <td>Email Address</td>
                  <td>${emailAddress}</td>
                </tr>
                <tr>
                  <td>PRC License Number</td>
                  <td>${PRCLicenseNumber}</td>
                </tr>
                <tr>
                  <td>Clinic Landline</td>
                  <td>${clinicLandlineNumber}</td>
                </tr>
                <tr>
                  <td>Alternative Landline</td>
                  <td>${alternativeClinicLandlineNumber}</td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                </tr>
                <tr>
                  <td colspan="2"><strong>Primary Office</strong></td>
                </tr>
                <tr>
                  <td>Hospital/Clinic</td>
                  <td>${
                    primaryOffice.hospital.value === "Other"
                      ? primaryOffice.otherHospital
                      : primaryOffice.hospital.value
                  }</td>
                </tr>
                <tr>
                  <td>Clinic Hours</td>
                  <td>${primaryOffice.clinicHours}</td>
                </tr>
                <tr>
                  <td>Street Address</td>
                  <td>${primaryOffice.streetAddress}</td>
                </tr>
                <tr>
                  <td>Province</td>
                  <td>${primaryOffice.province.value}</td>
                </tr>
                <tr>
                  <td>City</td>
                  <td>${primaryOffice.city.value}</td>
                </tr>
                <tr>
                  <td colspan="2"><strong>Secondary Office</strong></td>
                </tr>
                <tr>
                  <td>Hospital/Clinic</td>
                  <td>${
                    secondaryOffice.hospital.value === "Other"
                      ? secondaryOffice.otherHospital
                      : secondaryOffice.hospital.value || "N/A"
                  }</td>
                </tr>
                <tr>
                  <td>Clinic Hours</td>
                  <td>${secondaryOffice.clinicHours || "N/A"}</td>
                </tr>
                <tr>
                  <td>Street Address</td>
                  <td>${secondaryOffice.streetAddress || "N/A"}</td>
                </tr>
                <tr>
                  <td>Province</td>
                  <td>${
                    secondaryOffice.province
                      ? secondaryOffice.province.value
                      : "N/A"
                  }</td>
                </tr>
                <tr>
                  <td>City</td>
                  <td>${
                    secondaryOffice.city ? secondaryOffice.city.value : "N/A"
                  }</td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                </tr>
                <tr>
                  <td colspan="2"><strong>Referral</strong></td>
                </tr>
                <tr>
                  <td>Full Name</td>
                  <td>${referral}</td>
                </tr>
                <tr>
                  <td>Flyers Received</td>
                  <td>${flyersReceived}</td>
                </tr>
                <tr>
                  <td>You will likely enroll your first patient within:</td>
                  <td>${planToEnrollPatient}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <p class="subtitle is-primary"><strong>Consent and Authorization</strong></p>
            <ul>
                ${handleBuildData(drConsentAndAuthorization)}
            </ul>

          </div>
        </div>
      </body>
    </html>
  `
}
