import api from "./api"

export const sendMessage = async ({ message, recipient, callback }) => {
  const response = await api
    .post("telerivet/send-message/", {
      message_content: message,
      contact_number: recipient,
    })
    .then(() => {
      if (callback) callback()
    })

  return response
}
