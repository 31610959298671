import React from "react"
import classNames from "classnames"
import { ErrorMessage } from "formik"

import CheckBoxSection from "../checkbox/checkbox-section"

import {
  pxConsentAndAuthorization,
  drConsentAndAuthorization,
} from "./utils/consent-and-authorization-options"
import styles from "./enrollment-form.module.scss"

const termsAndConditionBlurb = (
  <span>
    I agree to the{" "}
    <a href="/terms-and-conditions" target="__blank">
      terms and conditions
    </a>
    .
  </span>
)

const ConsentAndAuthSection = ({ data }) => (
  <ul className="help">
    {data.map(item => (
      <li dangerouslySetInnerHTML={{ __html: item }} />
    ))}
  </ul>
)

const ConsentAndAuthorization = ({ values, isPatient, setFieldValue }) => {
  let termsAndConditionsData = isPatient
    ? pxConsentAndAuthorization
    : drConsentAndAuthorization

  return (
    <>
      <div className="title is-5 mt-2">
        Consent and Authorization <br />
      </div>
      <article className="message">
        <div className={classNames("message-body", styles["collapsible"])}>
          <section className="content">
            <p className="has-text-weight-bold">
              By submitting this form, I confirm that:
            </p>
            <ConsentAndAuthSection data={termsAndConditionsData} />
          </section>
        </div>
      </article>

      <div className="checkbox-section">
        <CheckBoxSection
          options={[termsAndConditionBlurb]}
          values={values.consentAndAuthorization}
          setFieldValue={setFieldValue}
          fieldName="consentAndAuthorization"
        />
        <div className="has-text-danger is-size-7">
          <ErrorMessage name="consentAndAuthorization" />
        </div>
      </div>
    </>
  )
}

export default ConsentAndAuthorization
