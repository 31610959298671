import React, { Fragment } from 'react'
import ContentFold from '../page-content/content-fold'
import Media from 'react-media'

const FormTemplate = ({ title, children }) => {
  return (
    <ContentFold>
      <br />
      <Media query={{ maxWidth: 853 }}>
        {matches => {
          return (
            <Fragment>
              <h3 className="title pb-2">{title}</h3>
              {children}
            </Fragment>
          )
        }}
      </Media>
    </ContentFold>
  )
}

export default FormTemplate
