import React from "react"
import hospitals from "../../assets/hospitals.json"
import FormSelect from "../elements/Form/FormSelect"

const handleLoadHospitalOptions = (inputValue, callback) => {
  let sortedHospitals = [...hospitals]
  sortedHospitals.shift()
  sortedHospitals = sortedHospitals.sort((a, b) =>
    a.value.localeCompare(b.value)
  )
  sortedHospitals = [hospitals[0], ...sortedHospitals]

  callback(
    sortedHospitals
      .filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()))
      .slice(0, 50)
  )
}

const HospitalSelect = props => {
  return (
    <FormSelect
      loadOptions={handleLoadHospitalOptions}
      options={hospitals}
      defaultOptions
      isAsync
      helper="Please select 'Other' if you can't find your hospital/clinic in the list."
      {...props}
    />
  )
}

export default HospitalSelect
