import {
  zendeskApi,
  zendeskFileUpload,
  generateFileUploadTokens,
} from "./zendeskService"
import { sendEmail } from "./emailService"
import {
  // enrollmentinformation,
  patientEnrollmentTemplate,
  doctorEnrollmentTemplate,
} from "./enrollmentEmailTemplate"
import { handleError } from "../services/handleError"

import { sendMessage } from "../services/telerivet"
import {
  pxConsentAndAuthorization,
  drConsentAndAuthorization,
} from "../components/forms/enrollment-form/utils/consent-and-authorization-options"

// import { attachFiles } from './api'
const FROM_EMAIL = "MedGrocer <pulsecare@medgrocer.com>"

const handleBuildData = input => {
  let output = ""
  input.forEach(data => {
    output += ` - ${data} \n\n`
  })
  return output
}

export async function sendPatientEnrollmentForm(
  enrollmentFormDetails,
  fileArray,
  callback,
  enrollmentTextMessageTemplate,
  errorCallback
) {
  const {
    lastName,
    firstName,
    middleName,
    birthMonth,
    birthDate,
    birthYear,
    mobileNumber,
    emailAddress,
    streetAddress,
    city,
    province,
    addressType,
    secondaryContact,
    doctor,
    latestRxDateMonth,
    latestRxDateDate,
    latestRxDateYear,
    lastCheckupDateMonth,
    lastCheckupDateDate,
    lastCheckupDateYear,
    isTransferringPatient,
    experienceAdverseEvents,
    heartCarePatientCode,
    planYourFirstOrder,
  } = enrollmentFormDetails

  const uploadedFiles = await zendeskFileUpload(fileArray)
  const fileTokens = generateFileUploadTokens(uploadedFiles)
  let tags = ["patient_enrollment"]
  if (process.env.NODE_ENV !== "production") {
    tags.push("test")
  }
  zendeskApi(process.env.GATSBY_ZENDESK_EMAIL)
    .post("/requests.json", {
      request: {
        type: "patient-enrollment-form",
        subject: `${
          process.env.NODE_ENV === "production" ? "" : "[TEST]"
        } PCP Patient Enrollment Form of ${firstName} ${lastName}`,
        details: {
          firstName: `${firstName}`,
          middleName: `${middleName}`,
          lastName: `${lastName}`,
          birthday: `${birthMonth} ${birthDate} ${birthYear}`,
          mobileNumber: `${mobileNumber}`,
          email: `${emailAddress}`,
          streetAddress: `${streetAddress}`,
          city: `${city}`,
          province: `${province}`,
          doctor: `${doctor}`,
          secondaryContactFullName: secondaryContact.name,
          secondaryContactMobileNumber: secondaryContact.mobileNumber,
        },
        tags: tags,
        comment: {
          body: `
            Personal Details
            Last Name: ${lastName}
            First Name: ${firstName}
            Middle Name: ${middleName}
            Birthdate: ${`${birthMonth} ${birthDate}, ${birthYear}`}
            Mobile Number: ${mobileNumber}
            Email: ${emailAddress}
            You are a patient transferring from the Heart Care Program: ${isTransferringPatient}${
            isTransferringPatient === "Yes"
              ? `\nHeart Care Patient Code: ${heartCarePatientCode}\nYou have reported any adverse events: ${experienceAdverseEvents ||
                  "N/A"}`
              : ""
          }
            You will likely order within: ${planYourFirstOrder}
            ---
            Address
            Type: ${addressType}
            Street: ${streetAddress} 
            City: ${city.value}
            Province: ${province.value}
            ---
            Secondary Contact Person
              Full Name: ${secondaryContact.name}
              Mobile Number: ${secondaryContact.mobileNumber}
            ---
            Doctor: ${doctor}
            Prescription Details
            Date of Last Prescription: ${`${latestRxDateMonth} ${latestRxDateDate} ${latestRxDateYear}`}
            Date of Last Checkup: ${`${lastCheckupDateMonth ||
              ""} ${lastCheckupDateDate || ""} ${lastCheckupDateYear || ""}`}
            ---
            Consent And Authorization:
            ${handleBuildData(pxConsentAndAuthorization)}
            `,
          uploads: fileTokens,
        },
      },
    })
    .then(async response => {
      if (!!emailAddress) {
        await sendEmail(
          emailAddress,
          FROM_EMAIL,
          `${
            process.env.NODE_ENV === "production" ? "" : "[TEST]"
          } Novartis Pulse Care Program: Patient Enrollment for ${firstName} ${lastName} has been Received`,
          patientEnrollmentTemplate({ ...enrollmentFormDetails })
        )
      }
      if (enrollmentTextMessageTemplate) {
        await sendMessage({
          recipient: mobileNumber,
          message: enrollmentTextMessageTemplate({
            zendeskID: response.data.request.id,
          }),
          callback: () => callback(),
        })
      }
    })
    .catch(error => {
      sendEmail(
        [emailAddress, "paphelpdesk@medgrocer.com"],
        FROM_EMAIL,
        `${
          process.env.NODE_ENV === "production" ? "" : "[TEST]"
        } Novartis Pulse Care Program: Patient Enrollment for ${firstName} ${lastName} has been received but didn't reach Zendesk`,
        patientEnrollmentTemplate({ ...enrollmentFormDetails })
      )
      let errorMessage =
        "There was an error sending your enrollment. Please try again."
      handleError({ error, errorCallback, errorMessage })
    })
}

export async function sendDoctorSignupInformation(
  signupForm,
  fileArray,
  callback,
  enrollmentTextMessageTemplate,
  errorCallback
) {
  const {
    firstName,
    lastName,
    middleName,
    emailAddress,
    mobileNumber,
    PRCLicenseNumber,
    clinicLandlineNumber,
    alternativeClinicLandlineNumber,
    primaryOffice,
    secondaryOffice,
    referral,
    flyersReceived,
    planToEnrollPatient,
  } = signupForm

  let tags = ["doctor_enrollment"]
  if (process.env.NODE_ENV !== "production") {
    tags.push("test")
  }

  zendeskApi(process.env.GATSBY_ZENDESK_EMAIL)
    .post("/requests.json", {
      request: {
        type: "Doctor Sign up Form",
        subject: `${
          process.env.NODE_ENV === "production" ? "" : "[TEST]"
        } PCP Doctor Sign Up from ${firstName} ${lastName}`,
        requester: {
          name: `${firstName} ${lastName}`,
          email: `${!!emailAddress ? emailAddress : `pulsecare@medgrocer.com`}`,
        },
        tags: tags,
        comment: {
          body: `
          Personal Details
          Last Name: ${lastName}
          First Name: ${firstName}
          Middle Name: ${middleName}
          Email: ${emailAddress}
          Mobile Number: ${mobileNumber}
          PRC License Number: ${PRCLicenseNumber}
          Clinic Landline Number: ${clinicLandlineNumber}
          Alternative Clinic Landline: ${alternativeClinicLandlineNumber}
          ---
          Primary Office:
            Hospital/Clinic: ${
              primaryOffice.hospital.value === "Other"
                ? primaryOffice.otherHospital
                : primaryOffice.hospital.value
            }
            Street Address: ${primaryOffice.streetAddress} 
            City: ${primaryOffice.city.value} 
            Province: ${primaryOffice.province.value}
            Clinic Hours: ${primaryOffice.clinicHours}
          ---
          Secondary Office:
            Hospital/Clinic: ${
              secondaryOffice.hospital.value === "Other"
                ? secondaryOffice.otherHospital
                : secondaryOffice.hospital.value
            }
            Street Address: ${secondaryOffice.streetAddress} 
            City: ${secondaryOffice.city.value} 
            Province: ${secondaryOffice.province.value}
            Clinic Hours: ${secondaryOffice.clinicHours}
          ---
          Referral: ${referral}
          Flyers Received: ${flyersReceived}
          You will likely enroll your first patient within: ${planToEnrollPatient}
          ---
          Consent And Authorization:
          ${handleBuildData(drConsentAndAuthorization)}
          `,
        },
      },
    })
    .then(async response => {
      // attachFiles(response.data.request.id, fileArray, () => {
      await sendEmail(
        emailAddress,
        FROM_EMAIL,
        `${
          process.env.NODE_ENV === "production" ? "" : "[TEST]"
        } Novartis Pulse Care Program: Enrollment for Dr. ${firstName} ${lastName} has been Approved`,
        doctorEnrollmentTemplate({ ...signupForm })
      )

      if (enrollmentTextMessageTemplate) {
        await sendMessage({
          recipient: mobileNumber,
          message: enrollmentTextMessageTemplate({
            zendeskID: response.data.request.id,
          }),
          callback: () => callback(),
        })
      }
    })
    .catch(error => {
      sendEmail(
        [emailAddress, "paphelpdesk@medgrocer.com"],
        FROM_EMAIL,
        `${
          process.env.NODE_ENV === "production" ? "" : "[TEST]"
        } Novartis Pulse Care Program: Enrollment for Dr. ${firstName} ${lastName} has been submitted but didn't reach Zendesk`,
        doctorEnrollmentTemplate({ ...signupForm })
      )

      let errorMessage =
        "There was an error sending your enrollment. Please try again."
      handleError({ error, errorCallback, errorMessage })
    })
}
