import axios from 'axios'
// Invoke URL: https://txriunlwrj.execute-api.us-east-1.amazonaws.com/default/sendEmail
export function sendEmail(to, from, subject, html) {
  axios
    .post(process.env.GATSBY_SEND_EMAIL_API_URL, {
      to: to,
      from: from,
      subject: subject,
      html: html,
    })
    .then(() => {})
    .catch(error => console.log(error))
}
