export const pxConsentAndAuthorization = [
  "I agree to participate in the Novartis Pulse Care Patient Access Program as described on the terms and conditions attached herein.",
  "I acknowledge that Novartis Pulse Care Patient Access Program reserves the right to deny my application or deny my approved application because of any incorrect, incomplete, or misleading information and documents relating to my application.",
  "I agree that Novartis Pulse Care Patient Access Program can contact me to update and clarify any information that I have given. Novartis Pulse Care Patient Access Program can also send me SMS to provide reminders.",
  "I agree that there is a need for Novartis Pulse Care Patient Access Program to collect my personal information for the Novartis Pulse Care Patient Access Program and for the purpose of any adverse events about individual patients or product quality complaints relating to its products that are being identified. I am agreeable that as part of the Novartis Pulse Care Patient Access Program, Novartis Pulse Care Patient Access Program will collect, access, store, use, and share my personal information. You may store my personal information for a maximum period of five (5) years from the end of the Novartis Pulse Care Patient Access Program without any further consent needed from my end, then, it will be securely destroyed.",
  "I allow Novartis Pulse Care Patient Access Program to contact me (and my physician) in case of any adverse event experienced while enrolled in the program and to be followed-up as necessary to complete the report information on my adverse event.",
  "I allow Novartis Pulse Care Patient Access Program to contact me for any needed follow-up pertaining to my enrollment or to remind me of my next scheduled visit.",
  "I allow Novartis to use anonymized data extracted from my personal information for the regular monitoring and improvement of the Novartis Pulse Care Patient Access Program, and internal business purposes related to the Novartis Pulse Care Patient Access Program.",
  "I hereby authorize my caregiver, as I indicated above, to claim my medicines on my behalf on the next scheduled visit as long as he/she presents his/her valid ID with my authorization letter.",
  "If I would like to access, update, or correct my information or if I do not want my information retained on the database, I should in the first instance contact MedGrocer. If I would like a copy of the Novartis Privacy Policy or have any other privacy issues, I will contact ph.cdpo@novartis.com.",
  "Finally, I agree that my participation is voluntary and that I am free to withdraw at any time.",
]

export const drConsentAndAuthorization = [
  "I have examined and diagnosed the patients I referred to this program and determined that these patients will benefit from treatment of Sacubitril-Valsartan.",
  "I hereby enroll these patients to the Novartis Pulse Care Patient Access Program for Sacubitril-Valsartan for the indications stated in their prescriptions.",
  "I agree to report within 24 hours to Novartis any adverse event from Sacubitril-Valsartan that these patients may experience while on the Program and to provide seriousness and causality assessment.",
  "I allow to be contacted for any follow-ups for these adverse events and for any inquiries or clarifications relating to my patients' prescriptions for the purpose of ensuring patient safety and well-being.",
  "I attest that these patients have been diagnosed with any of the approved indications and that Sacubitril-Valsartan is prescribed on-label.",
  "I agree to follow up on these patients at least every three (3) months to evaluate response and tolerability to prescribed treatment.",
  "If I would like to access, update, or correct your information or if I do not want your information retained on the database, I should in the first instance contact MedGrocer. If I would like a copy of the Novartis Privacy Policy or have any other privacy issues, I will contact ph.cdpo@novartis.com.",
]

export const drDataPrivacyOptions = [
  "The above information is correct and I give Novartis and MedGrocer the authority to collect, store, and use my information for the Pulse Care Program and related administrative activities. Subject to certain exceptions allowed by the law, I may revise my personal information and inform MedGrocer to update and store the same. MedGrocer may store my personal information for a period of six (6) years from the end of the program without any further consent needed from my end. Novartis Healthcare Philippines, Inc. (Novartis) acknowledges and respects the privacy of individuals.",
  "Novartis Healthcare Philippines, Inc. (Novartis) acknowledges and respects the privacy of individuals.",
  "Novartis has engaged MedGrocer to collect and hold your information in order to organize your participation in the Pulse Care Program and for communication with you regarding the program. By completing this form and providing the information requested, you consent to the third party organization(s) named, using that information for the purposes outlined. Novartis may access and disclose the information to respond to regulatory authorities or if required by law.",
  "You are not obliged to provide the information and you may choose not to. If you do not provide this information, Novartis may not be able to provide the support to you under the program or administer the program effectively or at all.",
  "If you would like to access, update or correct your information or if you do not want your information retained on the database, you should in the first instance contact MedGrocer. If you would like a copy of the Novartis Privacy Policy or have any other privacy issues, please contact the Novartis Data Privacy Officer Kathy Tan (kathy.tan@novartis.com).",
]

export const communicationsOptions = [
  "By availing of the benefits granted by the program, I consent to receiving mail, email, calls, text messages, and/or other means of communication from MedGrocer and its partners. I understand and acknowledge that I have the right to decline communications at any time upon notifying MedGrocer.",
]

export const pxProgramEndOptions = [
  "I understand that Novartis and MedGrocer has the right to terminate the program prior to the end date.",
]

export const drProgramEndOptions = [
  "I understand that Novartis has the right to terminate the program prior to the end date and the Novartis representative may retrieve all the remaining communication materials.",
]

export const pxWaiverOptions = [
  "I will indemnify and hold MedGrocer, Novartis, and their partners free from any liabilities that may arise as a consequence of my decision to join the Pulse Care Program. I also acknowledge that MedGrocer or Novartis shall not be responsible nor liable for any loss or damage incurred or suffered as a consequence of: A) any delay or inability of MedGrocer to perform any of its obligations due to any mechanical, data processing, telecommunication failure, act of God, civil disturbance, any event outside MedGrocer’s control, or as a consequence of any fraud or forgery; and B) any damage to or loss of or inability to retrieve any data that may be stored in the MedGrocer database.",
]

export const drWaiverOptions = [
  "I will indemnify and hold MedGrocer, Novartis, and their partners free from any liabilities that may arise as a consequence of my decision to join the Pulse Care Program. I also acknowledge that MedGrocer or Novartis shall not be responsible nor liable for any loss or damage incurred or suffered as a consequence of: A) any delay or inability of MedGrocer to perform any of its obligations due to any mechanical, data processing, telecommunication failure, act of God, civil disturbance, any event outside MedGrocer’s control, or as a consequence of any fraud or forgery; and B) any damage to or loss of or inability to retrieve any data that may be stored in the MedGrocer database.",
]
