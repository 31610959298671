import React from "react"
import { Field, ErrorMessage } from "formik"
import classNames from "classnames"

import FieldContainer from "./field-container"

const FormsSection = ({
  sectionTitle,
  subtitle,
  fields,
  handleChange,
  handleMaxLength,
  onChangeInput,
  errors,
  touched,
  values,
}) => {
  const handleOnKeyPress = (event, field) => {
    if (field.isNumeric && isNaN(event.key)) event.preventDefault()
  }
  return (
    <div className={classNames("mb-2", { "pb-1": !!sectionTitle })}>
      {!!sectionTitle && (
        <h4
          className={classNames("has-text-weight-bold is-size-5 mt-2", {
            "mb-0 pb-0": !!subtitle,
          })}
        >
          {sectionTitle}
        </h4>
      )}
      {!!subtitle && <h5 className="is-size-7 mb-0">{subtitle}</h5>}
      {fields.map(field => (
        <FieldContainer
          label={field.label}
          name={field.name}
          isRequired={field.isRequired}
          helper={field.helper || null}
          key={field.name}
        >
          <Field
            name={field.name}
            className="input"
            type={field.type}
            // placeholder={field.placeholder}
            onChange={
              !!field.maxLength
                ? onChangeInput(handleChange, field.maxLength)
                : handleChange
            }
            onKeyPress={event => handleOnKeyPress(event, field)}
          />
          <div className="has-text-danger is-size-7">
            <ErrorMessage name={field.name} />
          </div>
        </FieldContainer>
      ))}
    </div>
  )
}

export default FormsSection
