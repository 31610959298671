import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DrEnrollmentForm from "../components/forms/enrollment-form/doctor/dr-enrollment-form"

const DoctorEnrollmentForm = () => {
  return (
    <Layout>
      <SEO
        title="Enroll as a Doctor"
        keywords={[
          `novartis`,
          `medgrocer`,
          `pulse care`,
          `entresto`,
          `sacubitril-valsartan`,
        ]}
      />
      <DrEnrollmentForm />
    </Layout>
  )
}

export default DoctorEnrollmentForm
