import React from "react"
import { Field, ErrorMessage } from "formik"
import FieldContainer from "./field-container"
import provinces from "../../../assets/locs.json"
import AddressTypeChoices from "./patient/address-type-choices"
import Address from "../../forms/Address/Address"

import HospitalSelect from "../HospitalSelect"

const AddressDetails = ({
  isPatient,
  title,
  officeType,
  values,
  isRequired,
  setFieldValue,
}) => {
  const addressFieldValues = {
    addressType: "addressType",
    streetAddress: `${officeType}.streetAddress`,
    province: `${officeType}.province`,
    city: `${officeType}.city`,
  }

  const currentHospital = isPatient
    ? values["hospital"]
    : values[`${officeType}`]["hospital"]

  return (
    <>
      <div className="title is-5">{title}</div>
      {isPatient ? null : (
        <>
          {/* <FieldContainer label="Hospital/Clinic" isRequired={isRequired}>
            <Field name={`${officeType}.hospital`} className="input" />
            <div className="has-text-danger is-size-7">
              <ErrorMessage name={`${officeType}.hospital`} />
            </div>
          </FieldContainer> */}
          <HospitalSelect
            label="Hospital/Clinic Name"
            name={`${officeType}.hospital`}
            isRequired={isRequired}
            value={values[officeType].hospital}
          />

          {currentHospital.value === "Other" && (
            <FieldContainer
              label="Please indicate your hospital/clinic"
              isRequired={isRequired}
            >
              <Field
                name={
                  isPatient ? "otherHospital" : `${officeType}.otherHospital`
                }
                className="input"
                placeholder="Philippine General Hospital"
              />
              <div className="has-text-danger is-size-7">
                <ErrorMessage
                  name={
                    isPatient ? "otherHospital" : `${officeType}.otherHospital`
                  }
                />
              </div>
            </FieldContainer>
          )}

          <FieldContainer
            label="Clinic Hours"
            isRequired={isRequired}
            helper="Available date and time for you to receive Doctor Package, e.g. Mon/Thurs 9AM-2PM, Tues/Fri 3PM-6PM"
          >
            <Field name={`${officeType}.clinicHours`} className="input" />
            <div className="has-text-danger is-size-7">
              <ErrorMessage name={`${officeType}.clinicHours`} />
            </div>
          </FieldContainer>
        </>
      )}

      <Address
        values={values[officeType]}
        isRequired={isRequired}
        setFieldValue={setFieldValue}
        isNationwide
        fieldNames={addressFieldValues}
        className="mt-1"
      />

      <br />
    </>
  )
}

export default AddressDetails
