import React from "react"
import classNames from "classnames"

const FieldContainer = ({ children, label, isRequired, helper, className }) => {
  return (
    <div className="mt-2">
      <label
        className={classNames("label", className, {
          "has-text-weight-bold": !!isRequired,
        })}
      >
        {label} {!!isRequired && <span className="has-text-danger">*</span>}
        <br /> <label className="help">{helper}</label>
      </label>
      {children}
    </div>
  )
}

export default FieldContainer
