import React, { useState, Fragment } from "react"
import { navigate } from "gatsby"
import { Formik, Form, Field, ErrorMessage } from "formik"

import Message from "../../../elements/Message"

// import Dropzone from '../../../dropzone/dropzone'
import {
  DoctorDefaultValues,
  DoctorPersonalDetails,
  planToEnrollPatient,
} from "./doctorInitializers"
import { doctorValidationSchema } from "../helpers"
import { sendDoctorSignupInformation } from "../../../../utils/enrollmentFormService"
import { handleMaxLength } from "../form-utils"

import FormTemplate from "../../form-template"
import FieldContainer from "../field-container"
import FormsSection from "../forms-section"
import AddressDetails from "../address-details"
import ConsentAndAuthorization from "../consent-and-authorization"
import { enrollmentTextMessageTemplate } from "../templates/enrollmentTextTemplate"

import styles from "../enrollment-form.module.scss"

const BriefingGuide = (
  <Fragment>
    <span className="help">
      Refer to{" "}
      <a
        href={process.env.GATSBY_BRIEFING_GUIDE_LINK}
        className="has-text-weight-bold"
        target="_blank"
        rel="noopener noreferrer"
      >
        this link
      </a>{" "}
      for the Patient Briefing Guide.
    </span>
  </Fragment>
)

const materialOptions = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
]

const DrEnrollmentForm = ({ values }) => {
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleErrorSubmit = ({ message }) => {
    setIsLoading(false)
    setErrorMessage(message)
  }

  const callback = resetForm => () => {
    setIsLoading(false)
    resetForm()
    navigate("/confirmation")
  }
  const handleSubmit = (values, { resetForm, setValues }) => {
    // if (dropzoneFile.length !== 0) {
    setIsLoading(true)
    sendDoctorSignupInformation(
      values,
      [],
      callback(resetForm),
      enrollmentTextMessageTemplate,
      handleErrorSubmit
    )
    // }
  }

  return (
    <FormTemplate title="Doctor Enrollment Form">
      <Formik
        initialValues={DoctorDefaultValues}
        onSubmit={handleSubmit}
        validationSchema={doctorValidationSchema}
        enableReinitialize
      >
        {({ values, errors, handleChange, setFieldValue }) => {
          return (
            <Form autoComplete="off">
              <FormsSection
                fields={DoctorPersonalDetails}
                values={values}
                sectionTitle="Personal Details"
                onChangeInput={handleMaxLength}
                handleChange={handleChange}
              />
              <AddressDetails
                isPatient={false}
                officeType="primaryOffice"
                title="Primary Office"
                values={values}
                isRequired
              />
              <AddressDetails
                isPatient={false}
                officeType="secondaryOffice"
                title="Secondary Office"
                values={values}
                isRequired={false}
              />
              <div className="title is-5">Referral</div>
              <FieldContainer
                label="Full Name"
                isRequired
                helper="Please provide the full name of the Medical Representative who referred you to the Pulse Care Program. "
              >
                <Field className="input" name="referral" />
                <div className="has-text-danger is-size-7">
                  <ErrorMessage name="referral" />
                </div>
              </FieldContainer>

              <FieldContainer
                label="Have you received copies of Patient Briefing Guide flyers?"
                helper={BriefingGuide}
                isRequired
              >
                {materialOptions.map(option => (
                  <div key={option.value}>
                    <label>
                      <Field
                        type="radio"
                        className={styles.radio}
                        name="flyersReceived"
                        value={option.value}
                      />
                      <span className="ml-1">{option.label}</span>
                    </label>
                  </div>
                ))}
                <div className="has-text-danger is-size-7">
                  <ErrorMessage name="flyersReceived" />
                </div>
              </FieldContainer>
              <FieldContainer
                label="You will likely enroll your first patient within:"
                helper="To serve your patients better, please let us know when you will likely enroll your first patient."
                isRequired
              >
                {planToEnrollPatient.map(option => (
                  <div key={option.value}>
                    <label>
                      <Field
                        type="radio"
                        className={styles.radio}
                        name="planToEnrollPatient"
                        value={option.value}
                      />
                      <span className="ml-1">{option.label}</span>
                    </label>
                  </div>
                ))}
                <div className="has-text-danger is-size-7">
                  <ErrorMessage name="planToEnrollPatient" />
                </div>
                <br />
              </FieldContainer>
              <ConsentAndAuthorization
                values={values}
                setFieldValue={setFieldValue}
              />

              {Object.keys(errors).length ? (
                <p className="help has-text-danger my-1">
                  You might have missed some required fields. Please double
                  check your entries.
                </p>
              ) : null}

              {errorMessage && <Message color="danger">{errorMessage}</Message>}

              <button
                className={`button is-primary ${
                  isLoading ? "is-loading" : " "
                }`}
                type="submit"
              >
                Submit Enrollment Form
              </button>
            </Form>
          )
        }}
      </Formik>
    </FormTemplate>
  )
}

export default DrEnrollmentForm
