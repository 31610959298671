import * as Yup from "yup"

export const yearNow = new Date(Date.now()).getFullYear()

export const orderValidationSchema = Yup.object().shape({
  customerEmail: Yup.string()
    .email("Invalid email")
    .required("This field is required"),
  customerFirstName: Yup.string().required("This field is required"),
  customerLastName: Yup.string().required("This field is required"),
  birthMonth: Yup.string().required("This field is required"),
  birthDate: Yup.string().required("This field is required"),
  birthYear: Yup.number()
    .required("This field is required")
    .min(1900, "Invalid year")
    .max(yearNow, "Invalid year"),
  mobileNumber: Yup.string()
    .required("This field is required")
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    ),
  authorizedPerson: Yup.object().when("contactPerson", {
    is: contactPerson => contactPerson.length === 0,
    then: Yup.object().shape({
      fullName: Yup.string().required("This field is required"),
      mobileNumber: Yup.string()
        .min(
          11,
          "Please input a valid mobile number in this format: 09991234567"
        )
        .max(
          11,
          "Please input a valid mobile number in this format: 09991234567"
        )
        .matches(
          /^09[0-9]{9}$/,
          "Please input a valid mobile number in this format: 09991234567"
        )
        .required("This field is required"),
    }),
  }),
  preferredDeliveryDate: Yup.object({
    month: Yup.object().shape({
      value: Yup.string()
        .required("This field is required")
        .nullable(),
    }),
    date: Yup.object().shape({
      value: Yup.number()
        .required("This field is required")
        .nullable(),
    }),
    year: Yup.number()
      .min(yearNow, "Please input a valid year")
      .required("This field is required"),
  }),
  prescribingDoctor: Yup.string().required("This field is required"),
  shippingAddress: Yup.object().shape({
    addressType: Yup.string().required("This field is required"),
    streetAddress: Yup.string().required("This field is required"),
    province: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    city: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    barangay: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
  }),
  hospital: Yup.object().shape({
    name: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    otherHospital: Yup.string().when("name", {
      is: hospital => hospital.value === "Other",
      then: Yup.string().required("This field is required"),
    }),
    city: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    province: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
  }),
})

export const patientValidationSchema = Yup.object().shape({
  lastName: Yup.string().required("This field is required"),
  firstName: Yup.string().required("This field is required"),
  middleName: Yup.string().required("This field is required"),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .required("This field is required")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    ),
  birthDate: Yup.number().required("This field is required"),
  birthMonth: Yup.string().required("This field is required"),
  birthYear: Yup.number()
    .required("This field is required")
    .min(1900, "Invalid year")
    .max(yearNow, "Invalid year"),
  // discountAvailed: Yup.string().required("This field is required"),
  emailAddress: Yup.string().email("Invalid email"),
  isTransferringPatient: Yup.string().required("This field is required"),
  experienceAdverseEvents: Yup.string().when("isTransferringPatient", {
    is: "Yes",
    then: Yup.string().required("This field is required"),
  }),
  heartCarePatientCode: Yup.string().when("isTransferringPatient", {
    is: "Yes",
    then: Yup.string(),
  }),
  planYourFirstOrder: Yup.string().required("This field is required"),
  doctor: Yup.string().required("This field is required"),
  streetAddress: Yup.string().required("This field is required"),
  city: Yup.object().shape({
    value: Yup.string().required("This field is required"),
  }),
  province: Yup.object().shape({
    value: Yup.string().required("This field is required"),
  }),
  addressType: Yup.string().required("This field is required"),
  consentAndAuthorization: Yup.array().min(1, "This section is required."),
  latestRxDateMonth: Yup.string().required("This field is required"),
  latestRxDateDate: Yup.string().required("This field is required"),
  latestRxDateYear: Yup.number()
    .required("This field is required")
    .min(1900, "Invalid year")
    .max(yearNow, "Invalid year"),
  lastCheckupDateYear: Yup.number()
    .min(1900, "Invalid year")
    .max(yearNow, "Invalid year"),
  lastConfinementDateYear: Yup.number()
    .min(1900, "Invalid year")
    .max(yearNow, "Invalid year"),
  secondaryContact: Yup.object().shape({
    name: Yup.string().required("This field is required"),
    mobileNumber: Yup.string()
      .required("This field is required")
      .min(11, "Please input a valid mobile number in this format: 09991234567")
      .max(11, "Please input a valid mobile number in this format: 09991234567")
      .matches(
        /^09[0-9]{9}$/,
        "Please input a valid mobile number in this format: 09991234567"
      ),
  }),
})

export const doctorValidationSchema = Yup.object().shape({
  lastName: Yup.string().required("This field is required"),
  firstName: Yup.string().required("This field is required"),
  middleName: Yup.string().required("This field is required"),
  mobileNumber: Yup.string()
    .required("This field is required")
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    ),
  PRCLicenseNumber: Yup.number()
    .required("This field is required")
    .max(9999999, "Invalid PRC License number."),
  // clinicLandlineNumber: Yup.string().required('This field is required'),
  emailAddress: Yup.string()
    .email("Invalid email")
    .required("This field is required"),
  referral: Yup.string().required("This field is required"),
  flyersReceived: Yup.string().required("This field is required"),
  planToEnrollPatient: Yup.string().required("This field is required"),
  primaryOffice: Yup.object().shape({
    clinicHours: Yup.string().required("This field is required"),
    hospital: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    otherHospital: Yup.string().when("hospital", {
      is: hospital => hospital.value === "Other",
      then: Yup.string().required("This field is required"),
    }),
    city: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    province: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    streetAddress: Yup.string().required("This field is required"),
  }),
  consentAndAuthorization: Yup.array().min(1, "This section is required."),
})
