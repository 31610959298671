export const DoctorDefaultValues = {
  lastName: "",
  firstName: "",
  middleName: "",
  mobileNumber: "",
  emailAddress: "",
  clinicLandlineNumber: "",
  alternativeClinicLandlineNumber: "",
  primaryOffice: {
    hospital: { label: "", value: "" },
    otherHospital: "",
    province: { label: "", value: "" },
    city: { label: "", value: "" },
    streetAddress: "",
    clinicHours: "",
  },
  secondaryOffice: {
    hospital: { label: "", value: "" },
    otherHospital: "",
    province: { label: "", value: "" },
    city: { label: "", value: "" },
    streetAddress: "",
    clinicHours: "",
  },
  PRCLicenseNumber: "",
  referral: "",
  flyersReceived: "",
  planToEnrollPatient: "",
  consentAndAuthorization: [],
}

export const DoctorPersonalDetails = [
  {
    type: "text",
    label: "Last Name",
    name: "lastName",
    isRequired: true,
    placeholder: "dela Cruz",
  },
  {
    type: "text",
    label: "First Name",
    name: "firstName",
    isRequired: true,
    placeholder: "Juan",
  },
  {
    type: "text",
    label: "Middle Name",
    name: "middleName",
    isRequired: true,
    placeholder: "Reyes",
  },
  {
    type: "number",
    label: "PRC License Number",
    name: "PRCLicenseNumber",
    placeholder: 77123,
    isRequired: true,
    maxLength: 7,
  },
  {
    type: "text",
    label: "Mobile Number",
    name: "mobileNumber",
    isRequired: true,
    helper:
      "Please ensure that your mobile number is correct as your enrollment details will be sent here.",
    placeholder: "09991234567",
    maxLength: 11,
    isNumeric: true,
  },
  {
    type: "text",
    label: "Clinic Landline",
    name: "clinicLandlineNumber",
    isRequired: false,
    placeholder: "88881234",
    maxLength: 11,
    isNumeric: true,
  },
  {
    type: "text",
    label: "Alternative Landline",
    name: "alternativeClinicLandlineNumber",
    isRequired: false,
    placeholder: "88881234",
    maxLength: 11,
    isNumeric: true,
  },
  {
    type: "email",
    label: "Email Address",
    name: "emailAddress",
    isRequired: true,
    helper:
      "Please ensure that your email address is correct as your enrollment details will be sent here.",
    placeholder: "jdelacruz@email.com",
  },
]

export const planToEnrollPatient = [
  { value: "The week", label: "The week" },
  { value: "The month", label: "The month" },
  { value: "I do not know", label: "I do not know" },
]
